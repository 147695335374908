/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Commands</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">grep "Mar 13" lastlog</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">tr "[A-Z]" "[a-z]" &lt; lastlog | grep "mar 12"</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">awk '{ print toupper ($0) }' lastlog</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">tr "[a-z]" "[A-Z]" &lt; lastlog I grep -i "mar 12"</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">awk '{ print $1 }' lastlog | sort | uniq</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">awk '{ print $1 }' lastlog I uniq</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">awk '{ print $2 }' lastlog | sort I uniq</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">grep lastlog "Mar 13"</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">grep Mar 13 lastlog</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">awk '{ print $1 }' lastlog | sort | uniq -c</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">grep "Mar13" lastlog</h5>
              <h5 class="dragBlock my-1 font-semibold cursor-pointer">grep -i "mar 12" lastlog sed 's/ [a-z]/[A-Z]/g'</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row">
                <div class="border border-solid border-white p-5 w-full">
                  <div class="flex items-center">
                    <h2>[comptia@localhost exercise]$</h2>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                      id="input0"
                      style="width: 20vw"
                    ></vue-draggable-container>
                  </div>
                  <div class="ubuntu-font mt-4">
                    <h3>eric pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                    <h3>david pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                    <h3>pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50) ann</h3>
                    <h3>chris pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                    <h3>carl pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                    <h3>joe pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                    <h3>lee pts/3 :0 Mon Mar 13 10:52 08:48 (1+12:50)</h3>
                  </div>
                </div>
                <div class="border border-solid border-white p-5 w-full mt-4">
                  <div class="flex items-center">
                    <h2>[comptia@localhost exercise]$</h2>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                      id="input1"
                      style="width: 20vw"
                    ></vue-draggable-container>
                  </div>
                  <div class="ubuntu-font mt-4">
                    <h3>COMPTIA PTS/1 :0 Sun Mar 12 14:20 — 09:48 (1+19:28)</h3>
                    <h3>CHRIS PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:S6)</h3>
                    <h3>REBOOT SYSTEM BOOT 3.10.0-693.21.1. Sun Mar 12 11:01 — 13:26 (2+02:24)</h3>
                    <h3>LEE PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:S6)</h3>
                    <h3>COMPTIA PTS/0 :0 Sun Mar 12 14:14 — 14:22 (00:07)</h3>
                    <h3>REBOOT SYSTEM BOOT 3.10.0-693.21.1. Sun Mar 12 11:02 — 13:26 (2+02:23)</h3>
                    <h3>DAVID PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:56)</h3>
                    <h3>COMPTIA PTS/0 :0 Sun Mar 12 09:53 — 10:59 (01:05)</h3>
                    <h3>COMPTIA :0 :0 Sun Mar 12 11:01 — 11:01 (00:00)</h3>
                    <h3>REBOOT SYSTEM BOOT 3.10.0-693.21.1. Sun Mar 12 10:59 — 13:26 (2+02:26)</h3>
                    <h3>COMPTIA :0 :0 Sun Mar 12 11:03 — 13:26 (2+02:22)</h3>
                    <h3>COMPTIA PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:56)</h3>
                    <h3>ANN PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:56)</h3>
                    <h3>CARL PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+18:56)</h3>
                    <h3>REBOOT SYSTEM BOOT 3.10.0-693.EL7.X Sun Mar 12 09:39 — 10:59 (01:19)</h3>
                    <h3>JOE PTS/2 :0 Sun Mar 12 14:52 — 09:48 (1+12:56)</h3>
                    <h3>COMPTIA :0 :0 Sun Mar 12 09:43 — DOWN (01:15)</h3>
                    <h3>COMPTIA PTS/0 :0 Sun Mar 12 11:04 — 11:34 (00:30)</h3>
                  </div>
                </div>
                <div class="border border-solid border-white p-5 w-full mt-4">
                  <div class="flex items-center">
                    <h2>[comptia@localhost exercise]$</h2>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                      id="input2"
                      style="width: 20vw"
                    ></vue-draggable-container>
                  </div>
                  <div class="ubuntu-font mt-4">
                    <h3>ann</h3>
                    <h3>carl</h3>
                    <h3>chris</h3>
                    <h3>comptia</h3>
                    <h3>david</h3>
                    <h3>eric</h3>
                    <h3>joe</h3>
                    <h3>lee</h3>
                    <h3>reboot</h3>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'grep "Mar 13" lastlog') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'tr "[a-z]" "[A-Z]" < lastlog I grep -i "mar 12"') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === "awk '{ print $1 }' lastlog | sort | uniq") {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
